<template>
  <div class="navigation">
    <div class="title">
      <span> 量表管理/ </span> <span class="black">套餐设置</span>
    </div>
    <el-card class="condition_style2">
      <div class="condition_div">
        <el-form :inline="true" class="flex">
          <el-form-item>
            <el-button type="primary" size="small" @click="add('')">新增套餐</el-button>
          </el-form-item>
          <div>
            <el-form-item>
              <el-input v-model="searchForm.title" placeholder="请输入套餐名称" suffix-icon="el-icon-search" clearable>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-select placeholder="请选择分类" filterable v-model="searchForm.status" clearable>
                <el-option v-for="(item, index) in startList" :key="index" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
        </el-table-column>
        <el-table-column prop="title" label="套餐名称" min-width="120" align="center"></el-table-column>
        <el-table-column prop="remark" label="套餐简介" min-width="250" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class='myNote'>{{scope.row.remark}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="topicNames" label="包含量表" min-width="180" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope" v-if="scope.row.topicNames">
            <div class='myNote'>{{scope.row.topicNames}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="adaptorTypeName" label="适读群体" min-width="120" align="center"></el-table-column>
        <el-table-column prop="remarks" label="备注" min-width="250" align="center">
        </el-table-column>
        <el-table-column prop="status" label="当前状态" min-width="80" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="最后操作时间" min-width="200" align="center"></el-table-column>
        <el-table-column prop="updateBy" label="最后操作人" min-width="100" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="130" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="add(scope.row.mainId)"> 编辑</el-button><span class="ge"> |</span>
            <el-button type="text" size="small" @click="deletes(scope.row)"> 删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="current" :size="size" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>
    <!-- 新增\编辑 -->
    <modal :obj="{
        title: edit ? '编辑套餐' : '新增套餐',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
      }" @submit="addSubmit">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="套餐名称：" prop="title">
          <el-input v-model="addForm.title" placeholder="请输入机构名称"></el-input>
        </el-form-item>
        <el-form-item label="套餐简介：" prop="remark">
          <el-input v-model="addForm.remark" placeholder="请输入套餐简介" type="textarea" :rows="4" show-word-limit
            maxlength="300"></el-input>
        </el-form-item>
        <el-form-item label="包含量表：" :required="true">
          <div class="topic" v-if="addForm.topicDetails&&addForm.topicDetails.length>0">
            <div v-for="(item, index) in addForm.topicDetails" :key="index">
              <div class="flex">
                <el-input style="width:260px;" v-model="item.name" placeholder="请选择量表"></el-input>
                <div class="zsgc">
                  <el-button class="span" type="text" @click="handleDelete(item,index)">删除</el-button>
                  <el-button class="span" type="text" @click="handleMoveUp(item,index)" :disabled="index === 0">上移
                  </el-button>
                  <el-button class="span" type="text" @click="handleMoveDown(item,index)"
                    :disabled="getFormLength(index)">下移</el-button>
                </div>
              </div>
            </div>
          </div>
          <el-button size="small" type="primary" @click="addCourses">添加量表</el-button>
        </el-form-item>
        <el-form-item label="适读群体：" prop="adaptorType">
          <el-select placeholder="请选择适读群体" class="w_100" v-model="addForm.adaptorType">
            <el-option v-for="(item, index) in startList" :key="index" :label="item.label" :value="item.value"
              v-show="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remarks">
          <el-input v-model="addForm.remarks" placeholder="备注" type="textarea" :rows="2"></el-input>
        </el-form-item>
      </el-form>
    </modal>
    <!-- 云文件 -->
    <modal :obj="{
        title: '量表',
        dialog: resetDialog,
        dialogText: 'resetDialog',
        form: fileInfo,
        ref: 'fileInfo',
        okText: '确认',
        height:'600px'
      }" @submit="dialogSubmit">
      <el-form :model="fileInfo" label-width="50px" ref="fileInfo">
        <el-form-item>
          <div class="flex">
            <el-input class="w_100" v-model="fileInfo.topicName" @input="$forceUpdate()" placeholder="请输入搜索文件内容"
              clearable prefix-icon="el-icon-search"></el-input>
            <el-button type="primary" style="height: 30px;margin-top: 4px;" size="small" @click="changeSearch(1)">查询
            </el-button>
          </div>
        </el-form-item>
        <div class="selectBox" v-if="topicList.length>0">
          <div class="dis" v-for="(item, index) in topicList" :key="index">
            <div :class="{active : active == item.topicId}" @click="clickItem(item)"> {{item.topicName}}</div>
          </div>
        </div>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "@/components/pagination";
  import modal from "@/components/modal";
  import {
    savePackage,
    packageList,
    packageInfo,
    updatePackage,
    delPackage,
    topicLists
  } from "@/api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        active: "",
        topicList: [],
        size: 10, //每页条数
        current: 1, //当前条数
        total: 0,
        searchForm: {
          title: "",
          adaptorType: "",
          status: ""
        },
        fileInfo: {
          id: "",
          topicName: "",
        },

        tableHeight: this.$util.getHeight(330),
        tableData: [],
        startList: [{
            value: 0,
            label: "所有",
          },
          {
            value: 3,
            label: "学生",
          },
          {
            value: 4,
            label: "家长",
          },
        ],
        addDialog: false,
        resetDialog: false,
        edit: "",
        addForm: {
          adaptorType: "",
          title: "",
          remark: "", //摘要
          remarks: "", //备注
          status: "0",
          topicAssignConfList: [],
          topicDetails: [],
        },
        rules: {
          title: [{
            required: true,
            message: "请输入机构名称"
          }],
          adaptorType: [{
            required: true,
            message: "请选择适读群体"
          }],
        },
      };
    },
    computed: {
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
    },
    created() {
      this.getList()
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.getTableHeight(330)
      })

    },
    methods: {
      getTableHeight(val) {
        this.tableHeight = this.$util.getHeight(val)
      },

      getList(val) {
        if (val) {
          this.current = 1;
        }
        this.$request(packageList(this.current, this.size), this.searchForm).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.tableData = data.records;
            this.total = Number(data.total);
          }
        });
      },
      //获取量表
      gettopicLists(val) {
        this.$request(topicLists, val ? {
          topicName: this.fileInfo.topicName
        } : {}).then((res) => {
          if (res.code === 0) {
            this.topicList = res.data
          }
        })
      },
      getFormLength(index) {
        if (index === this.addForm.topicDetails.length - 1) return true;
        else return false;
      },
      handleDelete(item, index) {
        this.$forceUpdate()
        this.addForm.topicDetails.splice(index, 1);

        //删除
      },
      handleMoveUp(item, index) {
        //上移
        this.$forceUpdate()
        this.addForm.topicDetails.splice(index - 1, 0, item);
        this.addForm.topicDetails.splice(index + 1, 1);

      },
      handleMoveDown(item, index) {
        //下移
        this.$forceUpdate()
        this.addForm.topicDetails.splice(index + 2, 0, item);
        this.addForm.topicDetails.splice(index, 1);

      },
      changeSearch(val) {
        this.gettopicLists(val)
      },
      addCourses() {
        this.fileInfo.topicName = ""
        this.resetDialog = true
        this.gettopicLists()

      },
      clickItem(item) {
        this.active = item.topicId
        this.fileInfo.topicName = item.topicName
        this.fileInfo.id = item.topicId

      },
      dialogSubmit() {
        this.resetDialog = false
        this.topicList.forEach((item) => {
          if (item.topicId == this.fileInfo.id) {
            this.addForm.topicDetails.push({
              name: item.topicName,
              id: item.topicId,
            })
          }
        })
        this.topicList = []
      },
      //删除
      deletes(val) {
        let text = "确定删除？";
        this.$util.isSwal(text, () => {
          this.$request(delPackage, {
            mainId: val.mainId
          }).then((res) => {
            if (res.code == 0) {
              this.$util.message("suc", "操作成功");
              this.getList();
            }
          });
        });
      },
      //   新增\编辑-弹框
      add(val) {
        this.edit = val;
        this.addDialog = true;
        this.addForm = {
          adaptorType: "",
          title: "",
          remark: "", //摘要
          status: "0",
          topicAssignConfList: [],
          topicDetails: []
        }
        if (this.$refs.addForm) {
          this.$refs.addForm.resetFields();
        }
        if (this.edit) {
          this.getPackageInfo(val);
        }
      },
      // 详情
      getPackageInfo(val) {
        this.$request(packageInfo, {
          mainId: val
        }).then((res) => {
          if (res.code == 0) {
            this.addForm = res.data
            this.addForm.topicDetails = []
            if (res.data.topicAssignConVofList && res.data.topicAssignConVofList.length) {
              res.data.topicAssignConVofList.forEach((item) => {
                this.addForm.topicDetails.push({
                  id: item.topicId,
                  name: item.topicName,
                })
              })
            }
          }
        });
      },
      //   新增编辑-上传接口
      addSubmit() {
        let url = this.edit ? updatePackage : savePackage;
        this.addForm.topicAssignConfList = []
        if (this.addForm.topicDetails && this.addForm.topicDetails.length > 0) {
          this.addForm.topicDetails.forEach((item, index) => {
            this.addForm.topicAssignConfList.push({
              topicId: item.id,
              assignSort: index,
            })
          })
          delete this.addForm.topicDetails
        } else {
          this.$util.message("err", "请添加量表");
          return
        }
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.$request(url, this.addForm).then((res) => {
              if (res.code === 0) {
                this.addDialog = false;
                this.$util.message("suc", "操作成功");
                this.getList();
              }
            });
          }
        });
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
    },
  };
</script>

<style lang="less" scoped>
  .navigation {
    .w_48_l {
      width: 60%;
      display: inline-block;
    }

    /deep/.w_100 {
      width: 480px !important;
      margin-right: 20px;
    }

    .topic {
      width: calc(100% - 40px);
      height: 200px;
      overflow-y: scroll;
      border: 1px solid #eee;
      padding: 20px;

      .zsgc {
        color: #409EFF;
        text-decoration: underline;

        .span {
          margin-left: 10px;
          cursor: pointer;
          text-decoration: underline;
        }
      }

    }

    .selectBox {
      height: 300px;
      line-height: 30px;
      text-align: center;
      overflow-y: scroll;
      border: 1px solid #eee;
      padding-bottom: 20px;
      margin: 0 50px;

      .dis {
        width: 100%;
        height: 40px;
      }

      .active {
        width: 99%;
        background: #eee;
        border: 1px solid #eee;
        cursor: pointer;
      }
    }

    .w_48 {
      width: 40%;
      display: inline-block;

      /deep/.el-form-item__content {
        margin-left: 12px !important;
      }

    }
  }
</style>